.Typist {
  display: inline;
}

li.active > a {
  color: #2f55d4 !important;
}

.pagination .active a {
  color: #ffffff !important;
}

a {
  &:hover {
    cursor: pointer;
  }
}

.accordian-custom {
  border: none;
}

#mt-1 {
  margin-top: 5px !important;
}

#mb-1 {
  margin-top: 5px !important;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.masonry-container {
  width: 100%;
  margin: auto;
}
.masonry {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: stretch;
  width: 100%;
  margin: auto;
}
.column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: stretch;
  flex-grow: 1;
}
.tile {
  img {
    width: 100%;
  }
}

.indicator-inactive,
.indicator-active {
  height: 12px !important;
  width: 6px !important;
  border-radius: 4px;
  border: none;
  margin-left: 7px;
  margin-right: 7px;
}

.indicator-inactive {
  background-color: #2f55d4 !important;
  opacity: 0.6;
}
.indicator-active {
  background-color: #2f55d4 !important;
  transform: rotate(45deg);
}

.custom-owl-dots {
  margin-top: 10px;
  text-align: center;
}

.classForContainer {
  position: fixed;
  right: -100px;
  bottom: 30px;
  transition: right 0.5s;
  cursor: pointer;
  background-color: rgba(47, 85, 212, 0.2);
  padding: 5px;
  align-items: center;
  border-radius: 5px !important;

  &:hover {
    background-color: $primary !important;
    svg {
      stroke: $white !important;
    }
  }

  svg {
    height: 16px;
    width: 16px;
    vertical-align: middle;
    overflow: hidden;
    margin-top: 2px;
    margin-left: 5px;
    fill: none;
    stroke: $primary !important;
  }
}

.classForTransition {
  right: 30px;
}
//masonry

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  background: grey;
  margin-bottom: 30px;
}

.accordion {
  .card {
    .faq {
      > .active-link {
        color: $primary !important;
      }
    }
  }
}

.hover-border {
  .nav-link {
    &:focus,
    &.active,
    &:active {
      outline: none !important;
      border: transparent !important;
    }
    &:hover {
      border-color: transparent !important;
    }
  }
}

.mdi-cog::before {
  content: "\F493" !important;
}
.custom-dots {
  .indicator-active {
    background-color: #2f55d4 !important;
  }
}
.custom-dots {
  .indicator-inactive {
    background-color: #6486e4 !important;
  }
}

.main-section {
  padding-top: 100px;
  min-height: 800px;
  // background-color: #6486e4 !important;
}

.admin-table th {
  text-align: center;
}
.admin-table td {
  text-align: center;
}
.admin-table button {
  padding: 3px 10px;
}

.search-label {
  font-weight: bold;
  font-size: small;
}

.quote-table {
  th {
    text-align: left;
  }
  th.right {
    text-align: right;
  }
  td {
    text-align: left;
  }
  td.right {
    text-align: right;
  }
  td.section-title {
    font-weight: bold;
  }
  td.info {
    padding-left: 30px;
  }
  td.category {
    text-align: center;
  }
  td.price {
    text-align: right;
    min-width: 150px;
  }
}



.step-section {
  padding-top: 50px;
  min-height: 400px;
  // background-color: #6486e4 !important;
  .row {
    min-height: 60px;
    align-items: center;
  }
  .row.hidden {
    min-height: 0;
    visibility: collapse;
  }
  .title {
    font-size: 22px;
    font-weight: bold;
  }
  .subtitle {
    font-size: 18px;
    font-weight: bold;
  }
}

// TEMPTEMPTEMP
.step-progressbar {
  li.error {
    display: block;
    margin: 0;
  }
}

.step-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
}
.step-action-btn {
  padding: 0.75rem 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: all 200ms ease-in-out;
}
.action-btn-secondary {
  border: 1px solid #d1d9eb;
}
.action-btn-secondary:hover {
  background-color: #f3f7ff;
  box-shadow: 0px 3px 6px 0 rgba(190, 201, 228, 0.3);
}
.action-btn-primary {
  background-color: #397cf6;
  border: 1px solid #397cf6;
  color: white;
}
.action-btn-primary:hover {
  background-color: #316ddb;
  border: 1px solid #316ddb;
  box-shadow: 0px 3px 6px 0 rgba(57, 124, 246, 0.3);
}
.step-action-btn.disabled {
  pointer-events: none;
  filter: opacity(0.7);
  cursor: default;
}

@font-face {
  font-family: 'Zurich';
  src: local('Zurich'), url(../fonts/zurich-cn-bt.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

.footer-teksan {
  font-size: 9.5pt;
  color: #ffffff;
  font-family: 'Zurich';
}
.footer-map {
  background-image: url(../images/teksan/footer_map2.png);
  background-size: cover;
  height: 172px;
  width: 360px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.teksan-btn {
  font-family: 'Zurich';
  font-weight: normal;
  color: #ffffff;
  background-color: $teksan;
  border-radius: 0px;
  padding: 4px 18px 2px 18px;
}
.teksan-btn:hover {
  color: #ffffff;
  background-color: #f15a22;
}
.homepage-btn {
  margin: 10px;
  width: 270px;
  font-size: 17.5pt;
  padding: 10px 0;
  border-color: #ffffff;
  border-width: 2px;
  border-radius: 10px;
  font-variant: small-caps;
}
.homepage-btn:hover {
  color: #ffffff;
  background-color: #f15a22;
}
.footer-links a {
  color: #ffffff;
}
#header-teksan {
  background-image: url(../images/teksan/header300.png);
  background-size: cover;
  height: 72px;
  font-family: 'Zurich';
}
#header-teksan-container {
  display: flex;
  height: 72px;
  justify-content: space-between;
  align-items: center;
}
#header-logo {
  margin-top: 12px;
  height: 60px;
  width: 240px;
}
#teksan-navigation {
  display: flex;
  margin: 0;
  flex-direction: row;
  list-style: none;
  
}
.header-btn {
  padding: 4px 18px 2px 18px;
  margin-left: 10px;
}

.stock-table tr {
  font-size: small;
}
.stock-table-title {
  background-color: #b4c6e7;
  //color: #ffffff;
  font-size: medium;
}
.stock-table-header {
  background-color: $teksan;
  color: #ffffff;
}